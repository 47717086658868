<template>
<!-- <form id="payment_form" @submit.prevent="processPayment"> -->
    <!-- Time Extension Selection -->
    <div  v-if="!isIframeVisible">
      <div class="mb-3">
      <label for="timeSelect" class="form-label">Select Time Extension</label>
      <select class="form-select" v-model="selectedTime" @change="calculateCost" id="amount">
        <option v-for="option in timeOptions" :key="option.value" :value="option.cost">
        {{ option.label }}
      </option>
      </select>
    </div>

    <div class="row mb-3">
      <div class="form-check col-md-4 align-self-end ms-3">
        <input class="form-check-input" type="checkbox" v-model="addPagePin" id="addPagePin">
        <label class="form-check-label form-label" for="addPagePin">
          Add Page PIN?
        </label>
      </div>
      <div class="col-md-6">
        <input type="text" class="form-control" v-model="pin" :disabled="!addPagePin" placeholder="Enter PIN">
      </div>
      <div class="row mb-3">
        <div class="col-md-12">
          <label for="email" class="form-label">Email</label>
          <input type="email" class="form-control" v-model="userEmail" id="userEmail" placeholder="Enter your email">
        </div>
      </div>
    </div>

    <div class="mb-3">
      <strong>Total Charge:</strong> <span>{{ totalCharge }}</span>
    </div>


    <button type="" class="btn btn-success w-100"  @click="processPayment">Process Card</button>
    </div> 
    
      <iframe 
      title="Payment Form"
      allowfullscreen
      @load="onIframeLoad"
      ref="myIframe"
      id="payment_iframe"
      :src="iframeSrc"
      style="width:100%; height:500px;" frameborder="0"
      v-show="isIframeVisible"
      ></iframe>
 

  <!-- </form> -->
  
</template>
  

<script>

import api from '../api.js';
export default {
  props: {
    uuidCode: {
      type: String, // Ensure you declare the correct type
      required: true, // Set to true if this prop is always required
    }
  },
  data() {
    return {
      selectedTime: 1.00, // default selection
      addPagePin: false,
      pin: '',
      totalCharge: "$1", // default charge
      selectedDays: 12,
      isIframeVisible: false,
      userEmail: '',
      iframeSrc: '',
      timeOptions: [
        { label: "12 days = $1", days: 12, cost: 1.00 },
        { label: "30 days = $2.40", days: 30, cost: 2.40 },
        { label: "3 months = $7.20", days: 90, cost: 7.20 },
        { label: "6 months = $14.60", days: 180, cost: 14.60 },
        { label: "1 year = $29.20", days: 365, cost: 29.20 }
      ]
    };
  },
  mounted() {
          // Listen for payment response from iframe
          window.addEventListener("message", async (event) => {
              console.log("Message received from iframe:", event.data); // Log complete message
              
              // Process the payment response
              var response = event.data;
              if (response && typeof response === 'object' && 'success' in response) {
                  // Check if the response indicates success or failure
                  if (response.success) {
                      // Payment succeeded
                      alert(`Payment processed successfully!`);
                      await this.afterPaymentUpdateStatus();
                  } else {
                      // Payment failed
                      // let errorMsg = `${response.message}`;
                      // alert(errorMsg); // Show error message

                       // Format detailed errors
                      if (response.errors && typeof response.errors === 'object' && Object.keys(response.errors).length) {
                          for (let key in response.errors) {
                              if (response.errors.hasOwnProperty(key)) {
                                  // Check if the error is an array
                                  if (Array.isArray(response.errors[key])) {
                                      errorMsg += `  ${key}: ${response.errors[key].join(", ")}\n`;
                                  } else if (typeof response.errors[key] === 'object') {
                                      // Handle nested objects by converting them to string
                                      errorMsg += `  ${key}: ${JSON.stringify(response.errors[key])}\n`;
                                  } else {
                                      errorMsg += `  ${key}: ${response.errors[key]}\n`; // Handle non-array, non-object errors
                                  }
                              }
                          }
                      } else {
                          errorMsg += "No additional errors.";
                      }

                      alert(errorMsg); // Show formatted error message
                  }
              }
          });
  },
  emits: ['close-modal'], // Declare the event explicitly
  methods: {
    calculateCost() {
      this.totalCharge = `$${this.selectedTime}`;
    },
    
    async onIframeLoad(){ 
      
      console.log("lod iframe");
      // if(this.iframeSrc != ""){
        var iframe = document.getElementById('payment_iframe');

      // Send amount and merchant_id to the iframe
      let amount = parseFloat(this.selectedTime); 
      console.log(amount);
      console.log("on load---start");
      var uniqId = this.generateUniqueNumber();
      iframe.onload = function() {
        
        var paymentData = {
          amount: amount,
          merchant_id: '89fdf4de0e3cf9a00a613ef031e2d9ba',
          type: "paymentData",
          transaction_id: uniqId
        };
        iframe.contentWindow.postMessage(paymentData, "*");
        console.log("on load---end");
      };


      // }
      
    },
    async processPayment() {
      console.log("processPayment ==",this.addPagePin, this.pin);
      if (this.addPagePin && this.pin == "") {
        alert("Pin is required.");
      }else if (this.userEmail == "") {
        alert("Email is required.");
      } else{
        try {
          // await this.afterPaymentUpdateStatus();
          this.iframeSrc = 'about:blank';
          // After a short delay, set the src back to the desired URL
          setTimeout(() => {
            this.iframeSrc ="https://chingup.com/card_payment/";
            this.isIframeVisible = true;
          }, 100); // Small delay to ensure the iframe resets
        } catch (error) {
          console.error('Error:', error);
          alert('Something went wrong. Please try later.');
        }
      }
     
    },
    modalClosed() {
      console.log('Modal was closed. Do something in the child component.');
      this.isIframeVisible = false;
      // Add any logic you want to handle when the modal closes

    },
    async afterPaymentUpdateStatus(){
      try {
      const selectedOption = this.timeOptions.find(option => option.cost === parseFloat(this.selectedTime));
        if (selectedOption) {
          this.selectedDays = selectedOption.days;
        }

        // // var formData = new FormData();
        // console.log('amount', this.selectedTime);
        // console.log('isPinSet', this.addPagePin);
        // console.log('pin', this.pin);
        // console.log('selectedDays', this.selectedDays);
     
        var formData = new FormData();
        formData.append('extend_day', this.selectedDays)
        formData.append('uuid_code', this.uuidCode)
        formData.append('isSetPin', this.addPagePin)
        formData.append('pin', this.pin)
        formData.append('email', this.userEmail)
        const response = await api.post('extendtime.php', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        });
        if (response.data.success) {
          
          alert(response.data.message);
          // console.log(response.data);
          // this.isIframeVisible = false;
          // this.addPagePin = false;
          // this.$emit('close-modal');
          window.location.reload();
        } else {
          alert(response.data.message);
          window.location.reload();
        }
      } catch (error) {
        console.error('Error:', error);
        alert('Something went wrong. Please try later.');
      }

    },
    generateUniqueNumber() {
      // Get the current timestamp in milliseconds
      const timestamp = Date.now().toString(); // This gives 13 digits

      // Generate a 3-digit random number
      const randomNumber = Math.floor(Math.random() * 900) + 100; // Ensures a 3-digit number

      // Concatenate and return the 16-digit unique number
      return timestamp + randomNumber;
  }

  },
   
};
</script>
  