import { createRouter, createWebHistory } from 'vue-router'
import Home from './components/Home.vue';
import About from './components/About.vue';
import Advertise from './components/Advertise.vue';



// Vue.use(VueRouter);

const routes = [
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/:paramValue',
      name: 'HomeWithParam',
      component: Home,
       props: true
    },
    {
      path: '/about',
      name: 'About',
      component: About
    },
    {
      path: '/advertise',
      name: 'Advertise',
      component: Advertise
    },
    
  ]
  
  

const router = createRouter({
    history: createWebHistory(),
    // history: createWebHistory("mitul"),
    routes
  })
  
 

  export default router