<template>
<div>
  <div v-html="htmlContent"></div>
</div>

</template>
  
<script>
import api from '../api.js';
export default {
  data() {
    return {
      htmlContent: '',
    };
  },
  mounted() {
    this.fetchHtmlContent();
  },
  methods: {
    async fetchHtmlContent() {
      try {
        const response = await api.get('get-about.php');
        this.htmlContent = response.data.html;
      } catch (error) {
        console.error('Error fetching HTML content:', error);
      }
    },
  },
};
</script>

<style scoped>
         
</style>
