<template>
    <header class="bg-light mb-2">
        <div class="container">
            <nav class="navbar navbar-expand-lg navbar-light bg-light">
                <a class="navbar-brand" href="/">
                    <img src="@/assets/images/pyast_logo.png" alt="Website Logo" width="100px">
                </a>
                <!-- Add the hamburger menu button for mobile -->
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <!-- Collapsible menu -->
                <div class="collapse navbar-collapse justify-content-end" id="navbarNav">
                    <ul class="navbar-nav mr-auto">
                        <li class="nav-item"><router-link class="nav-link"  :class="{ 'active': isHomeActive }" :to="generateLink('/')">HOME</router-link></li>
       
                        <li class="nav-item"><router-link class="nav-link"  :class="{ 'active': isAboutActive }" :to="generateLink('/about')">ABOUT</router-link></li>
                        <li class="nav-item"><router-link class="nav-link"  :class="{ 'active': isAdvertiseActive }" :to="generateLink('/advertise')">ADVERTISE</router-link></li>
                    </ul>
                </div>
            </nav>
        </div>
    </header>
    <router-view class="container" id="main-div"></router-view>

    <footer class="bg-light py-4 mt-3">
        <div class="container">
            <p class="text-center">{{footerText}}</p>
        </div>
    </footer>
</template>

<script>
import api from './api.js';

export default {
  name: 'App',
  data() {
    return {
      footerText: '© 2024 Pyast',
    }
  },
  async mounted() {
    try {
        const response = await api.get(`initial_check.php?footer=yes`);
        if (response.data.success) {
          this.footerText = '© 2024 Pyast serving '+ response.data.uuid_count +' pages and ' + response.data.total_upload_file_image + ' files.' ;
        }
      
      } catch (error) {
        this.error = "Failed to fetch data: " + error.message;
      } finally {
        this.loading = false;
      }
  },
  methods: {
    // Generate a link with current query parameters
    generateLink(path) {
      // Get current query parameters
      const queryParams = this.$route.query;
      return { path, query: queryParams };
    }
  },
  computed: {
    // Check if the current route is either '/' or dynamic paths like '/abcd'
    isHomeActive() {
      // Check if the current path is '/' or contains a dynamic parameter value
      return this.$route.name === 'Home' || this.$route.name === 'HomeWithParam';
    },
    isAboutActive() {
      return this.$route.name === 'About';
    },
    isAdvertiseActive() {
      return this.$route.name === 'Advertise';
    }
  },

}
</script>

<style>
/* nav {
  margin-bottom: 20px;
}
nav a {
  margin-right: 15px;
} */
</style>
