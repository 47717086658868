// import './assets/css/style.css'

import { createApp } from 'vue'
import App from './App.vue'
import router from './router';

// createApp(router).mount('#app')

// Vue.config.productionTip = false;
console.log("main js===");
const app = createApp(App)

app.use(router)
app.mount('#app')