<template>
  <div>
    <div v-html="htmlContent"></div> <!-- Render HTML content here -->
  </div>
</template>

  <script>
  import api from '../api.js';
export default {
  data() {
    return {
      htmlContent: '',
    };
  },
  mounted() {
    this.fetchHtmlContent();
  },
  methods: {
    async fetchHtmlContent() {
      try {
        const response = await api.get('get-advertise.php');
        this.htmlContent = response.data.html;
      } catch (error) {
        console.error('Error fetching HTML content:', error);
      }
    },
  },
};
</script>
