<template>
  <div class="home">
    <Loader :loading="isLoading" />
    <!-- Input and Button in the template -->
    <div class="row justify-content-center mb-3 sub-nav">
      <div class="col-md-6 d-flex align-items-center justify-content-end">
        <label for="searchInput" class="label-font-default">pyast.com/</label>
        <input type="text" id="searchInput" @input="validateInput" @keyup.enter="onEnter" v-model="searchCode"
          class="form-control me-2" style="max-width: 80px;" placeholder="Enter code">
        <button class="btn btn-primary " title="Go to the page" @click="goToUrl">Go</button>
        <button class="btn btn-primary ms-2" title="Share page" @click="sharePage"><i class="bi bi-share"></i></button>
      </div>
      <div class="col-md-6 align-items-center d-flex justify-content-left add-time">
        <span class="ms-2 text-secondary">Page erase in <span class="blinking-timer">{{ formattedTime }}</span> 
        <button
            class="btn btn-primary ms-2" title="Extend page time." @click="addTime">Add Time</button> 
            </span>
      </div>
    </div>

    <div class="row mt-2">
      <!-- Drag-and-Drop and File Browser -->
      <div class="col-md-6">
        <div class="drop-zone" @dragover.prevent @drop.prevent="handleDrop" @click="triggerFileInput">
          <span class="material-icons-outlined upload-icon"> file_upload </span>
          <h3 for="textarea" class="dynamic-message"> Drag & drop files here </h3>
          <label class="label"> or
            <span class="browse-files">
              <input ref="fileInput" type="file" style="display: none;" @click.stop @change="handleFileUpload" />
              <span class="browse-files-text">browse file</span>
              <span>from device</span>
            </span>
          </label>
          <!-- Progress Overlay -->
          <div v-if="isUploading" class="progress-overlay">
            <div class="progress-container">
              <div class="progress-bar" :style="{ width: uploadProgress + '%' }"></div>
              <span class="text-white">{{ uploadProgress }}% Uploading..</span>
            </div>
          </div>
        </div>

      </div>

      <div class="col-md-6 text-center">
        <textarea id="textarea" v-model="textAreaContent" class="form-control" placeholder="Copy & paste text here"
          rows="5"></textarea>
        <div class="d-flex gap-2 justify-content-center action">
          <div class="bg-white gap-2 b-action">
            <button class="btn btn-primary btn-sm" title="Paste text" @click="pasteText">Paste</button>
            <button class="btn btn-warning btn-sm ms-2"  title="Clear text" @click="clearText">Clear</button>
            <button class="btn btn-success btn-sm ms-2" title="Save text" @click="saveText">Save</button>
          </div>
        </div>
      </div>

    </div>


    <div class="file-list" v-if="files.length">
      <!-- Loop through files array to dynamically create file cards -->
      <div class="file-card" v-for="file in files" :key="file.file_name">
        <button class="edit-btn" title="Edit" @click="editFileName(file)">
          <i class="bi bi-pencil-square"></i>
        </button>
        <!-- If the file is an image, show the image preview; otherwise, show a placeholder icon -->
        <img v-if="file.isImage" :src="file.fullPath" :alt="file.file_name" class="file-preview" />
        
        <img 
          v-else-if="isVideo(file.fullPath)" 
          src="../assets/images/video-thumb.png" 
          :alt="file.file_name" 
          class="file-preview" 
        />
        <!-- Check if the file is an audio -->
        <img 
          v-else-if="isAudio(file.fullPath)" 
          src="../assets/images/audio-thumb.png" 
          :alt="file.file_name" 
          class="file-preview" 
        />
        <img v-else src="../assets/images/file_temp.png" :alt="file.file_name" class="file-preview" />
        <div class="file-info">
          <!-- File name -->
          <p class="file-name">{{ file.original_file_name }}</p>
          <div class="file-actions">
            <!-- Download button -->
            <a :href="file.fullPath" :download="file.original_file_name" >
              <button class="download-btn" title="Download"><i class="bi bi-download"></i></button>
            </a>
            <!-- Delete button -->
            <button class="download-btn" title="Put file on another page" @click="moveFile(file)">
              <img src="@/assets/images/move_file_icon.svg" alt="Download" style="width: 20px; height: 20px;">
            </button>
            <button class="download-btn" v-if="isPDF(file.fullPath)"   title="Preview" @click="previewFile(file)"><i class="bi bi-eye"></i></button>
            <button class="delete-btn" title="Delete" @click="deleteFile(file)"><i class="bi bi-trash3-fill"></i></button>
          </div>
        </div>
      </div>
    </div>

    <div class="card-list">
      <div class="card" v-for="item in items" :key="item.id">
        <button class="edit-btn" title="Edit" @click="editTextItem(item)">
          <i class="bi bi-pencil-square"></i>
        </button>
        <div class="card-content">
          <p class="card-text" v-html="convertTextToLinks(item.text)"></p>
        </div>
        <div class="card-actions">
          <button :class="['copy-btn', { 'copied': isCopied }]" title="Copy" @click="copyText(item.text, item.id)">
            <i class="bi bi-copy"></i>
          </button>
          <button class="download-btn" title="Put text on another page." @click="moveText(item)">
            <img src="@/assets/images/move_file_icon.svg" alt="Download" style="width: 20px; height: 20px;">
          </button>
          <button class="download-btn" title="Preivew" @click="previewText(item.text)"><i class="bi bi-eye"></i></button>
          <button class="delete-btn" title="Delete" @click="deleteItem(item.id)">
            <i class="bi bi-trash3-fill"></i>
          </button>
        </div>
        <!-- Notification message -->
        <div v-if="isCopied && copiedItemId === item.id" class="notification">
          Text copied successfully!
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="extendTimeModal" tabindex="-1" aria-labelledby="extendTimeModalLabel" aria-hidden="true" ref="extendTimeModal">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="extendTimeModalLabel">Extend Expiration Time</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">

            <payment ref="paymentComponent" :uuid-code="uuid" @close-modal="closeModal"></payment>

          </div>
        </div>
      </div>
    </div>

    <!-- Move Modal -->
    <div class="modal fade" id="moveFileModal" tabindex="-1" aria-labelledby="moveFileModal" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content shadow-lg rounded-lg">
          <div class="modal-header bg-primary text-white">
            <h5 v-if="selectedMoveFile" class="modal-title" id="extendTimeModalLabel">Put file on another page</h5>
            <h5 v-else class="modal-title" id="extendTimeModalLabel">Put text on another page</h5>
            <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <h6 class="fw-bold"></h6>
            <div class="d-flex align-items-center mb-4">
              <label for="putInput" class="label-font-default me-2">pyast.com/</label>
              <input type="text" id="putInput" @input="validatePutInput" v-model="putFileCode" class="form-control me-2"
                style="max-width: 120px;" placeholder="Enter code">
              <button v-if="selectedMoveFile" class="btn btn-success btn-sm"
                @click="putFile(selectedMoveFile, null)">Put</button>
              <button v-else class="btn btn-success btn-sm" @click="putFile(null, selectedMoveText)">Put</button>
            </div>

            <div v-if="isPutRequest" class="alert alert-success text-center" id="successMessage">
              <strong>Success!</strong> put to <a :href="putSuccessURl"
                class="text-decoration-underline">{{ putSuccessURl }}</a>.
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Error Modal -->
    <div class="modal fade" id="errorModal" tabindex="-1" role="dialog" aria-labelledby="errorModalLabel"
      aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="errorModalLabel">Opps!</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">

            </button>
          </div>
          <div class="modal-body">
            {{ errorMessage }}
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal for image preview -->
    <div class="modal fade" id="imageModal" tabindex="-1" aria-labelledby="imageModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="imageModalLabel">{{ isEditing ? 'Edit' : 'Preview' }}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body text-center" v-if="imagePreviewUrl">
            <img v-if="imagePreviewUrl" :src="imagePreviewUrl" alt="Image Preview" class="img-fluid" />
          </div>
          
          <div v-else>
            <div v-if="isEditing">
              <textarea v-model="tempEditText" class="form-control text-preview p-3" :style="{ height: editableTextObj ? '60vh' : '20vh', 'overflow-y': 'auto' }"></textarea>
            </div>
            <textarea v-else v-model="textPreview" readonly class="form-control text-preview p-3" :style="{ height: editableTextObj ? '60vh' : '50vh', 'overflow-y': 'auto' }"></textarea>
            <!-- <div v-else v-html="convertTextToLinks(textPreview)" class="text-preview p-3" style="max-height: 60vh; overflow-y: auto;"></div> -->
          </div>
          <div class="modal-footer">
            <button v-if="imagePreviewUrl"  type="button"  @click="copyImageUrl" class="btn btn-primary" data-bs-dismiss="modal">Copy URL</button>
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            <button v-if="isEditing" type="button" class="btn btn-primary"  @click="saveEditedText(editableTextObj)">Save</button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Structure -->
    <div class="modal fade" id="shareModal" tabindex="-1" role="dialog" aria-labelledby="shareModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header bg-primary text-white">
            <h5 class="modal-title" id="shareModalLabel">Share this page</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <!-- URL input (disabled) -->
            <div class="input-group mb-3">
              <input type="text" class="form-control" :value="shareUrl" id="shareUrlInput" disabled>
              <button class="btn btn-outline-secondary" @click="copyLink">Copy Link</button>
            </div>
            <small>Copy the link and share it with others!</small>
          </div>
        </div>
      </div>
    </div>

    <!-- pin model -->
    <div class="modal fade" id="pinModal" tabindex="-1" aria-labelledby="pinModalLabel" aria-hidden="true" data-bs-backdrop="static">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="pinModalLabel">Enter Your PIN</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" @click="closeVerifyPin" aria-label="Close"></button>
          </div>
          
          <!-- Modal Body for PIN and Forgot PIN -->
          <div class="modal-body">
            <!-- PIN Input Section -->
            <div v-if="!forgotPin">
              <div class="form-group">
                <label for="pinInput">PIN</label>
                <input type="password" class="form-control ps-2" id="pinInput" @keydown.enter="verifyPin" v-model="strPin" placeholder="Enter your PIN" required>
              </div>
              <!-- Remember Password Checkbox -->
              <div class="form-group form-check">
                <input type="checkbox" class="form-check-input" id="rememberPassword" v-model="rememberPassword">
                <label class="form-check-label" for="rememberPassword">Remember Password</label>
              </div>
              <!-- Forgot PIN Button -->
              <div class="mt-3 text-center">
                <button type="button" class="btn btn-link" @click="forgotPin = true">Forgot PIN?</button>
              </div>
            </div>

            <!-- Email Input Section for PIN Reset -->
            <div v-if="forgotPin">
              <div class="form-group">
                <label for="emailInput">Enter your email to reset PIN</label>
                <input type="email" class="form-control ps-2" id="emailInput" v-model="email" placeholder="Enter your email">
              </div>
            </div>
          </div>
          
          <!-- Modal Footer -->
          <div class="modal-footer">
            <button v-if="!forgotPin" @click="verifyPin" class="btn btn-primary">Submit</button>
            <button v-if="forgotPin" @click="resetPin" class="btn btn-primary">Reset PIN</button>
            <button v-if="forgotPin" @click="forgotPin = false" class="btn btn-secondary">Back to PIN</button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="isFilePreview" class="pdf-preview-overlay">
      <div class="pdf-preview-header">
        <button class="close-btn" @click="closePreview">✖</button>
      </div>
      <!-- <iframe :src="filePreviewUrl" class="pdf-viewer" frameborder="0"></iframe> -->
       
      <PDF  v-if="isPDF(filePreviewUrl)" 
        :data="filePreviewUrl" 
        type="application/pdf" 
        class="pdf-viewer" :src="filePreviewUrl" />
    </div>
  </div>
</template>
  
<script>
import api from '../api.js';
import Payment from "./Payment.vue";
import Loader from './Loader.vue';
import PDF from "pdf-vue3";

export default {
  data() {
    return {
      textAreaContent: '',
      clipboardContent: '',
      image: null,
      progress: 0,
      message: '',
      fileData: null,   // Holds the file data (base64 or URL)
      fileName: null,   // Name of the uploaded file
      isImage: false,   // Flag to check if the file is an image
      files: [], // List of uploaded files
      uploadProgress: 0, // Track upload progress percentage
      items: [],
      uuid: '',
      searchCode: '',
      errorMessage: '',
      remainingTime: 30,
      timer: null,
      isCopied: false,
      copiedItemId: null,
      isUploading: false,
      selectedMoveFile: null,
      selectedMoveText: null,
      putFileCode: "",
      isPutRequest: false,
      putSuccessURl: "",
      imagePreviewUrl: '',
      textPreview: '',
      shareUrl: '',
      isPinSet: 0,
      strPin: '',
      forgotPin: false, 
      email: '',
      isLoading: false,
      isEditing: false,
      editableTextObj: null,
      editableFileObj: null,
      tempEditText: '',
      rememberPassword: false,
      isFilePreview : false,
      filePreviewUrl : '',
    };
  },
  props: ['paramValue'],
  components: {
    Payment,
    Loader,
    PDF
  },
  async mounted() {
    
    const textarea = document.getElementById('textarea');
    textarea.addEventListener('input', function() {
      const lineHeight = parseInt(window.getComputedStyle(textarea).lineHeight);
            const linesToLeave = 2; // Number of lines to leave visible
            // Scroll to keep the last two lines visible
            textarea.scrollTop = textarea.scrollHeight - (lineHeight * linesToLeave);
    });
    
    const modalElement = this.$refs.extendTimeModal;
    modalElement.addEventListener('hidden.bs.modal', () => {
      this.closeExtendTimeModal(); // Call the closeModal method when modal is closed
    });
    var a = "";
    if (this.paramValue) {
      // Regular expression to allow only numbers and letters (uppercase and lowercase)
      const regex = /^[a-zA-Z0-9]*$/;
      if (!regex.test(this.paramValue)) {
        a = this.paramValue.replace(/[^a-zA-Z0-9]/g, '');
      } else {
        a = this.paramValue;
      }
    }
    if (a != "") {
      this.uuid = a;
    }
    await this.initialCheck();
    await this.checkPin();
  },
  computed: {
    formattedTime() {
      // const minutes = Math.floor(this.remainingTime / 60);
      // const seconds = this.remainingTime % 60;
      // return `${this.padZero(minutes)}:${this.padZero(seconds)}`;
      const hours = Math.floor(this.remainingTime / 3600);
      const minutes = Math.floor((this.remainingTime % 3600) / 60);
      const seconds = this.remainingTime % 60;

      return `${this.padZero(hours)}:${this.padZero(minutes)}:${this.padZero(seconds)}`;

    },
  },
  methods: {
    async initialCheck() {
      try {
        const response = await api.get(`initial_check.php?initial_check=yes&a=${this.uuid}`);
        if (response.data.success) {
          const uuid = response.data.a;
          const rTime = response.data.remainingTime;
          const isPin = response.data.isSetPin;
          // this.$router.push({ query: { a: uuid } });
          this.$router.replace({ path: uuid });
          this.searchCode = uuid
          this.uuid = uuid
          this.remainingTime = rTime
          this.isPinSet = isPin
          // console.log("complete init");
          this.startTimer();
          var token = response.data.token;
         // console.log(token);
          localStorage.setItem('token', token);
        }else{
          alert("Something worng!")
        }
        
      } catch (error) {
        this.error = "Failed to fetch data: " + error.message;
      } finally {
         
      }
    },
    async fetchFiles() {
      console.log("fetc", this.uuid);
      try {
        const token = localStorage.getItem('token');
        const response = await api.get('fetch_data.php', {
          params: { 'uuid_code': this.uuid },
          headers: {
            'Content-Type': 'application/json',
          }
        });

        // Check the response from the server
        if (!response.data.success) {
          // Error from PHP (custom error handling)
          console.error('Error:', response.data.message);
          this.errorMessage = response.data.message;
          $('#errorModal').modal('show');
        } else {
          var data = response.data.data;
          // Filter text content
          const textArray = data.filter(item => item.type === "text");
          this.items = textArray;

          // Filter file content
          const fileArray = data.filter(item => item.type === "file" || item.type === "image");
          this.files = fileArray;

        }
      } catch (error) {
        console.error('Error fetching files:', error);
      }
    },
    startTimer() {
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.timer = setInterval(() => {
        if (this.remainingTime > 0) {
          this.remainingTime -= 1;
        } else {
          clearInterval(this.timer);
        }
      }, 1000);
    },
    padZero(number) {
      return number.toString().padStart(2, '0');
    },
    sharePage() {
      this.shareUrl = `https://pyast.com/${this.searchCode}`;
  
      $('#shareModal').modal('show');

    },
    copyLink() {
      const urlToCopy = this.shareUrl;
      
      // Use the Clipboard API to copy the URL
      navigator.clipboard.writeText(urlToCopy).then(() => {
        // alert('Link copied to clipboard!');
      }).catch(err => {
        console.error('Failed to copy: ', err);
      });
    },
    editTextItem(obj) {
      console.log("exit");
      this.imagePreviewUrl = null;
      this.isEditing = true;
      this.editableFileObj = null;
      this.editableTextObj = obj;
      this.tempEditText = obj.text;
      $('#imageModal').modal('show');
    },
    editFileName(obj) {
      console.log("exit");
      this.imagePreviewUrl = null;
      this.isEditing = true;
      this.editableTextObj = null;
      this.editableFileObj = obj;
      this.tempEditText = obj.original_file_name;
      $('#imageModal').modal('show');
    },
    copyImageUrl() {
    if (this.imagePreviewUrl) {
      var imgUrl = `https://pyast.com/${this.imagePreviewUrl}`;
      navigator.clipboard.writeText(imgUrl).then(() => {
        // alert('Image URL copied to clipboard!');
      }).catch(err => {
        console.error('Failed to copy image URL: ', err);
        alert('Failed to copy image URL. Please try again.');
      });
    }
  },
    convertTextToLinks(text) {
      // Regular expression to detect URLs
      // const urlPattern = /(\b(https?:\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|]))/gi;
      const urlPattern = /(https?:\/\/[^\s]+)/gi;
      
      // Replace URLs with anchor tags and convert spaces and line breaks to HTML entities
      return text.replace(/\n/g, '<br>')
                 .replace(/ /g, '&nbsp;')
                 .replace(urlPattern, '<a href="$&" target="_blank" rel="noopener noreferrer">$&</a>');
   
    },
    // Upload file to the server
    async uploadFile(file) {
      console.log("start - uploadFile");
      const formData = new FormData();
      formData.append('file', file);
      formData.append('uuid_code', this.uuid)
      this.isUploading = true;
      try {
        const response = await api.post('upload.php', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },

          onUploadProgress: (progressEvent) => {


            this.uploadProgress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            console.log(progressEvent.loaded);
          },
        });
        console.log("progressEvent - finished");
        this.isUploading = false;
        this.uploadProgress = 0;
        // Check the response from the server
        if (response.data.success) {
          // After successful upload, update the file URL
          const fileName = response.data.fileName;
          const fullPath = response.data.fullPath;
          const uploadID = response.data.upload_id;
          const originalFileName = response.data.original_file_name;
          const isImage = response.data.isImage;
          const type = response.data.isImage ? "image" : "file";
          this.files.push({
            file_name: fileName,
            original_file_name: originalFileName,
            isImage: isImage,
            fullPath: fullPath, // URL will be set after upload
            id: uploadID,
            type: type
          });
          console.log("upload - finished");
          // Reload the page if the device is Android
          if (/Android/i.test(navigator.userAgent)) {
                    window.location.reload();
          }
        } else {
          this.errorMessage = "Code not found!";
          $('#errorModal').modal('show');
        }

      } catch (error) {
        console.error('Error uploading file:', error);
        this.isUploading = false;
        this.uploadProgress = 0;
        alert('Error uploading file:', error);

      } finally {
        this.uploadProgress = 0;
      }
    },
    addTime() {

      $('#extendTimeModal').modal('show');
    },
    async handleGenerateNew() {
      try {
        const response = await api.get(`initial_check.php?new=true`);
        const uuid = response.data.a;
        this.$router.push({ query: { a: uuid } });
        this.searchCode = uuid
        this.uuid = uuid
      } catch (error) {
        console.error('Error fetching files:', error);
      }
    },
    copyText(textContent, id) {
      // Copy text to clipboard using the modern Clipboard API
      navigator.clipboard.writeText(textContent).then(() => {
        this.copySuccess = "Text copied to clipboard!";
        this.isCopied = true;  // Trigger animation

        this.copiedItemId = id;
        // Hide notification after 2 seconds
        setTimeout(() => {
          this.isCopied = false;
          this.copiedItemId = null;
        }, 2000);

      }).catch(err => {
        console.error("Failed to copy text: ", err);
      });
    },
    goToUrl() {
      // If the input field has a value, update the URL with ?a= parameter and reload
      if (this.searchCode) {
        this.$router.replace({ path: this.searchCode });
        setTimeout(() => {
          const newUrl = `${window.location.pathname}`;
          window.location.href = newUrl; // Reloads the page with the new URL
        }, 500); // 2000 milliseconds = 2 seconds

      }
    },
    onEnter() {
      this.goToUrl()
    },
    validateInput(event) {
      const input = event.target.value;
      // Regular expression to allow only numbers and letters (uppercase and lowercase)
      const regex = /^[a-zA-Z0-9]*$/;
      if (!regex.test(input)) {
        // Replace input value with the valid part only (removes special characters)
        this.searchCode = input.replace(/[^a-zA-Z0-9]/g, '');
      } else {
        // If input is valid, update the value
        this.searchCode = input;
      }
    },
    validatePutInput(event) {
      const input = event.target.value;
      // Regular expression to allow only numbers and letters (uppercase and lowercase)
      const regex = /^[a-zA-Z0-9]*$/;
      if (!regex.test(input)) {
        // Replace input value with the valid part only (removes special characters)
        this.putFileCode = input.replace(/[^a-zA-Z0-9]/g, '');
      } else {
        // If input is valid, update the value
        this.putFileCode = input;
      }
    },

    // Drag-and-drop file
    handleDrop(event) {
      console.log("handleDrop");
      const file = event.dataTransfer.files[0];
      if (file) {
        this.processFile(file);
      }
    },
    async saveText() {
      try {
        var formData = new FormData();
        formData.append('save_text', this.textAreaContent)
        formData.append('uuid_code', this.uuid)
 
        const response = await api.post('text_save.php', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        });
        if (response.data.success) {
          this.items.push({
            file_name: "",
            text: response.data.text,
            isImage: response.data.isImage,
            fullPath: "", // URL will be set after upload
            id: response.data.upload_id,
          });
          // alert('Text saved successfully!');
          this.clearText(); // Optional: Clear the text area after saving
        } else {
          alert('Error saving text.');
        }
      } catch (error) {
        console.error('Error:', error);
        alert('Something went wrong. Please try later.');
      }
    },
    // Trigger file input when clicked
    triggerFileInput() {
      console.log("triggerFileInput");
      this.$refs.fileInput.click();
    },
    // Process the uploaded file (handles both images and non-images)
    processFile(file) {
      this.fileName = file.name;
      this.isImage = file.type.startsWith("image/");

      const reader = new FileReader();

      // Handle image files
      if (this.isImage) {
        reader.onload = (event) => {
          this.fileData = event.target.result;  // Base64 image URL

        };
        reader.readAsDataURL(file);
      }
      // Handle non-image files
      else {
        reader.onload = (event) => {
          const blob = new Blob([event.target.result], { type: file.type });
          this.fileData = URL.createObjectURL(blob);  // Create a URL for the file

        };
        reader.readAsArrayBuffer(file);
      }
      // Upload file to server
      this.uploadFile(file);
    },

    // Delete file
    async deleteFile(file) {
      if (confirm("Are you sure you want to delete?")) {
        try {
          await api.delete(`delete.php?file=${encodeURIComponent(file.file_name)}&id=${encodeURIComponent(file.id)}`);
          this.files = this.files.filter(f => f.file_name !== file.file_name);
        } catch (error) {
          console.error('Error deleting file:', error);
        }
      }
    },
    isPDF(fileUrl) {
     
      const allowedExtensions = /\.(pdf|jpg|jpeg|png|gif|webp)$/i; // Case-insensitive regex
      return allowedExtensions.test(fileUrl);
    },
    isVideo(url) {
      const videoExtensions = ['.mp4', '.mkv', '.webm', '.avi', '.mov', '.wmv', '.flv'];
      return this.checkExtension(url, videoExtensions);
    },
    isAudio(url) {
      const audioExtensions = ['.mp3', '.wav', '.aac', '.ogg', '.flac', '.m4a'];
      return this.checkExtension(url, audioExtensions);
    },
    checkExtension(url, extensions) {
      const lowerCaseUrl = url.toLowerCase();
      return extensions.some(ext => lowerCaseUrl.endsWith(ext));
    },
    closePreview() {
      this.isFilePreview = false;      // Hide the preview overlay
      this.filePreviewUrl = '';      // Clear the file URL
    },
    async deleteItem(id) {
      if (confirm("Are you sure you want to delete?")) {
       try {
          await api.delete(`delete_record.php?id=${encodeURIComponent(id)}`);
          this.items = this.items.filter(f => f.id !== id);
        } catch (error) {
          console.error('Error deleting file:', error);
        }
      }
    },
    moveFile(file) {
      this.selectedMoveText = null;
      this.selectedMoveFile = file;
      this.isPutRequest = false;
      this.putFileCode = "";
      $('#moveFileModal').modal('show');
    },
    moveText(item) {
      this.selectedMoveFile = null;
      this.isPutRequest = false;
      this.putFileCode = "";
      this.selectedMoveText = item;
      $('#moveFileModal').modal('show');
    },
    async putFile(file, item) {
      if (this.putFileCode != "") {
        try {

          var para = null;
          if (file != null) {
            console.log('file : ', file);

            para = { 'upload_data_id': file.id, 'uuid_code': this.putFileCode, 'type': file.type };
          } else {
            console.log('item : ', item);
            para = { 'upload_data_id': item.id, 'uuid_code': this.putFileCode, 'type': "text" };

          }
          const response = await api.get('put_file.php', {
            params: para
          });

          // Check the response from the server
          if (response.data.success) {
            // Error from PHP (custom error handling)
            this.isPutRequest = true;
            const host = window.location.hostname;

            // Example logic to set dynamic URL based on environment
            if (host === 'localhost') {
              this.putSuccessURl = `http://localhost:5173/${this.putFileCode}`; // Local development URL
            } else {
              this.putSuccessURl = `https://${host}/${this.putFileCode}`; // Production URL
            }

          } else {
            var data = response.data.data;
            // Filter text content
          }
        } catch (error) {
          console.error('Error fetching files:', error);
        }
      }


    },
    previewFile(file) {
      if(file.isImage){
        this.isEditing = false;
        this.imagePreviewUrl = file.fullPath;
        $('#imageModal').modal('show');
      }else{
        this.filePreviewUrl = file.fullPath;
        this.isFilePreview = true;
      }
    },
    previewText(text) {
      this.isEditing = false;
      this.imagePreviewUrl = null;
      this.textPreview = text;
      $('#imageModal').modal('show');
    },
    removeAllWhitespaceAndNewlines(str) {
    // Remove all whitespace and newline characters
    return str
      .split('\n')
      .map(line => line.trim())
      .filter(line => line.length > 0)
      .join(' ')
      .replace(/\s+/g, ' ')
      .trim();
    },
    async saveEditedText(obj) {
      $('#imageModal').modal('hide');
      this.isEditing = false;
      this.isLoading = true;
      try {
        var formData = new FormData();
        if(this.editableTextObj != null){
          formData.append('edit_text', this.tempEditText);
          formData.append('upload_data_id', this.editableTextObj.id);
        }else{
          console.log(this.tempEditText);
          console.log("----------");
          let trimmedString = this.removeAllWhitespaceAndNewlines(this.tempEditText);
          console.log(trimmedString);
          formData.append('file_name', trimmedString);
          formData.append('upload_data_id', this.editableFileObj.id);
        }
 
        const response = await api.post('text_save.php', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        });
        if (response.data.success) {

          if(this.editableTextObj != null){
            this.editableTextObj.text = response.data.text;
          }else{
            let trimmedString = this.removeAllWhitespaceAndNewlines(this.tempEditText);
            this.editableFileObj.original_file_name = trimmedString;
          }
          this.isLoading = false;

        } else {
          this.isLoading = false;
          alert('Error saving text.');
        }
      } catch (error) {
        console.error('Error:', error);
        this.isLoading = false;
        alert('Something went wrong. Please try later.');
      }

    },
    // Handle file upload (Browse file)
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.processFile(file);
      }
    },
    pasteImage() {
      // Listen for paste events on the document
      console.log('Paste Image button clicked');
      document.addEventListener('paste', (event) => this.handlePaste(event));
    },
    async getClipboardImage() {
      try {
        // Access the clipboard data
        const clipboardItems = await navigator.clipboard.read();

        // Loop through clipboard items to find an image
        for (const clipboardItem of clipboardItems) {
          for (const type of clipboardItem.types) {
            // If the clipboard contains an image
            if (type.startsWith("image/")) {
              const blob = await clipboardItem.getType(type); // Get the image as a blob

              // Convert the blob to a base64 data URL for display
              const reader = new FileReader();
              reader.onload = (event) => {
                this.image = event.target.result; // Set the image data to display
              };
              reader.readAsDataURL(blob); // Convert the blob into a base64 data URL
              return; // Stop once we find the image
            }
          }
        }

        // If no image is found
        console.log("No image found in the clipboard.");
      } catch (error) {
        console.error("Error reading clipboard data:", error);
      }
    },
    handlePaste(event) {
      console.log("handlePaste");
      const items = (event.clipboardData || event.originalEvent.clipboardData).items;
      for (let item of items) {
        if (item.type.indexOf('image') !== -1) {
          const blob = item.getAsFile();
          this.image = URL.createObjectURL(blob); // Show the pasted image
          this.fileToUpload = blob; // Prepare the image for upload
        }
      }
    },
    uploadImage() {
      const formData = new FormData();
      formData.append('file', this.fileToUpload);

      api.post('upload.php', formData, {
        onUploadProgress: progressEvent => {
          this.progress = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
        }
      }).then(response => {
        this.message = 'Image uploaded successfully!';
      }).catch(error => {
        this.message = 'Error uploading image!';
      });
    },
    // Handle paste button
    pasteText() {
      navigator.clipboard.readText()
        .then(text => {
          this.clipboardContent = text;
          this.textAreaContent = text;
          // alert('Text copied from clipboard: ' + text);
        })
        .catch(err => {
          console.error('Failed to read clipboard: ', err);
        });
    },
    async checkPin(){
      if(this.isPinSet){
        $('#pinModal').modal('show');
        const savedPin = localStorage.getItem('savedPin');
        console.log("pin is =",savedPin);
        if (savedPin) {
          this.strPin = savedPin; // Pre-fill the PIN input
          this.rememberPassword = true; // Check the remember password checkbox
        }else{
          this.strPin = '';
        }
      }else{
        await this.fetchFiles();
      }
    },
    async verifyPin(){
      try {
        var formData = new FormData();
        formData.append('verifyPin', this.strPin)
        formData.append('uuid_code', this.uuid)
        const response = await api.post('initial_check.php', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        });
        if (response.data.success) {
          
          // alert('extend time successfully!');
          if (this.rememberPassword) {
            localStorage.setItem('savedPin', this.strPin); // Save PIN to local storage
          } else {
            localStorage.removeItem('savedPin'); // Remove saved PIN if checkbox is unchecked
            console.log("is false");
          }
          console.log(response.data);
          $('#pinModal').modal('hide');
          await this.fetchFiles();
        } else {
          alert(response.data.message);
        }
      } catch (error) {
        console.error('Error:', error);
        alert('Something went wrong. Please try later.');
      }

    },
    closeVerifyPin(){
      // this.$router.push('/home'); 
      this.strPin = '';
      this.email = '';
      this.forgotPin = false;
      window.location.href = "/";
    },
    closeExtendTimeModal(){
      console.log('Child notified of modal close');
  
      this.$refs.paymentComponent.modalClosed();
    },
    async resetPin() {
      // Your logic to handle PIN reset via email
      console.log('Reset PIN request sent to:', this.email);
      if (this.email === '') {
        alert('Email address is required.'); // Set error message
      } else if (!this.validateEmail(this.email)) {
        alert('Please enter a valid email address.'); // Set error message for invalid email format
      } else {
        try {
          this.isLoading = true;
          var formData = new FormData();
          formData.append('email', this.email)
          formData.append('uuid_code', this.uuid)
          const response = await api.post('forgot_pin.php', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            }
          });
          if (response.data.success) {
            this.isLoading = false;
            alert(response.data.message);
            window.location.reload();
          } else {
            this.isLoading = false;
            alert(response.data.message);
            window.location.reload();
            
          }
        } catch (error) {
          console.error('Error:', error);
          alert('Something went wrong. Please try later.');
        }
      }
    },
    validateEmail(email) {
      // Basic email validation regex
      const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      return re.test(email);
    },
    closeModal() {
      console.log("closeModal");
      $('#extendTimeModal').modal('hide');

    },
    // Handle paste and insert button
    clearText() {
      this.textAreaContent = '';
    }
  }

};
</script>

<style scoped>
.blinking-timer {
  /* animation: blink 1s step-start infinite; */
  color: #df2b2b;
  /* Change to your desired color */
  font-weight: bold;
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.form-control {
  padding: .375rem 0.05rem;
}

#textarea {
  border: 2px dashed #7b2cbf;
  border-radius: 15px;
  padding-left: inherit;
  height: 170px;
  padding-bottom: 29px;

}

.form-control:focus {
  box-shadow: unset !important;
}

.notification {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: #4caf50;
  color: white;
  text-align: center;
  padding: 10px;
  border-radius: 10px;
  animation: slideDownUp 2s ease-in-out forwards;

}

@keyframes slideDownUp {
  0% {
    transform: translateY(-100%);
  }

  10% {
    transform: translateY(0);
  }

  90% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-100%);
  }
}

.progress-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(90, 128, 179, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
}

.progress-container {
  text-align: left;
  font-size: 18px;
}

.progress-bar {
  background-color: white;
  height: 20px;
  width: 0;
  transition: width 0.5s ease;
}

.action {
  margin-top: -30px;
}

.action .btn {
  width: 67px !important;
}

.action .b-action {
  width: 237px !important;
}

@media (max-width: 768px) {
  #textarea {
    margin-top: 5px;
  }

  .add-time {
    margin-top: 5px;
  }

  .file-list {
    justify-content: center;
  }

  .card-list {
    justify-content: center;
  }

  .sub-nav .col-md-6 {
    justify-content: center !important;
  }

  .img-fluid {
    max-width: 100%;
    height: auto;
  }
}
.text-preview {
  max-height: 60vh;
  overflow-y: auto;
  /* white-space: pre-wrap; */
  word-wrap: break-word;
  font-family: inherit;
  text-align: left;
}

.edit-btn {
  position: absolute;
  top: -4px;
  right: -2px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2em;
  color: #007bff;
  
}

.edit-btn:hover {
  color: #0056b3;
}

/* file previe css */
.pdf-preview-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 99;
}

.pdf-preview-header {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1000;
}

.close-btn {
  /* background-color: #f00; */
  color: white;
  border: none;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  margin-right: 6px;
    margin-top: -7px;
}

.pdf-viewer {
  width: 90%;
  height: 90%;
  border: none;
  background-color: white;
}

</style>
